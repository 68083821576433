import dynamic from 'next/dynamic';
import Image from 'next/image';

import { CarouselItemBanner } from './interface';

const Link = dynamic(() => import('components/Link'));

const ItemBanner: React.FC<CarouselItemBanner> = ({ description, background, title, href }) => {
  return (
    <div className="carousel-banner">
      <Link href={href} className="carousel-banner__link" isTarget>
        <Image
          className="carousel-banner__bg-image"
          src={background}
          layout="fill"
          objectFit="cover"
        />
        <div className="carousel-banner__bg-gradient" />
        <div className="background-gradient">
          <div className="carousel-banner__box">
            {title && <h2 className="carousel-banner__title">{title}</h2>}
            {description && <p className="carousel-banner__description">{description}</p>}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ItemBanner;
